import React from "react";
import Layout from "components/layout";
import CreateAccountNew from "components/userAccount/CreateAccountNew";
import Seo from "components/seo";

function Index() {
  return (
    <Layout>
      <Seo title="Create Account - User" slug="/user/provider/createaccount" />
      <CreateAccountNew />
    </Layout>
  );
}

export default Index;
