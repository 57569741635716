import React, { useState, useRef, useContext } from "react";
import { Link } from "gatsby";
import { useForm } from "react-hook-form";
import { useTranslatorRegister } from "hooks/Forms/useTranslatorRegister";
import { CountryArray } from "utils/constants";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import Modal from "components/popup";
import Loader from "components/loader";
import DataProvider from "Context/DataContext";
import { useLocation } from "@reach/router";

function ThirdStepAccount({ createAccountData, setCurrentStep }) {
  const {
    firstStepData,
    cvUpload,
    nativeLangData,
    expertiseData,
    originalLangData,
    targetLangData,
    servicesData,
  } = useContext(DataProvider);

  const { usernameFinalStepData, setUsernameFinalStepData } =
    useContext(DataProvider);

  const { addressFinalStepData, setAddressFinalStepData } =
    useContext(DataProvider);
  const { cityFinalStepData, setCityFinalStepData } = useContext(DataProvider);
  const { staFinalStepData, setStaFinalStepData } = useContext(DataProvider);
  const { countryFinalStepData, setCountryFinalStepData } =
    useContext(DataProvider);
  const { zipFinalStepData, setZipFinalStepData } = useContext(DataProvider);

  const [showPayment, setShowPayment] = useState(false);
  const [token, setToken] = useState(false);
  const captchaRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [sameAddress, setSameAddress] = useState(false);

  const location = useLocation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { status, mutateAsync } = useTranslatorRegister();

  const onSubmit = async (data) => {
    if (!token) {
      alert("Please verify captcha");
      return;
    }
    var postData = [];
    const formData = new FormData();
    formData.append("first_name", firstStepData.first_name);
    formData.append("last_name", firstStepData.last_name);
    formData.append("uploading_file", cvUpload);
    formData.append("username", firstStepData.username);
    formData.append("email", firstStepData.email);
    formData.append("password", firstStepData.password);
    formData.append("phone_number", firstStepData.phone_number);
    formData.append("country", firstStepData.country);
    formData.append("native_language", nativeLangData);
    formData.append("field_of_expertise", expertiseData);
    formData.append("source_language", originalLangData);
    formData.append("target_language", targetLangData);
    formData.append("service", servicesData);
    formData.append("payment_method", data.payment_choose_later);
    formData.append("pagelink", location.href);

    if (data.is_billing_address_same_as_contact_address) {
      formData.append("payment_username", firstStepData.first_name);
      formData.append("payment_address", firstStepData.address);
      formData.append("payment_city", firstStepData.city);
      formData.append("payment_state", firstStepData.state);
      formData.append("payment_country", firstStepData.country);
      formData.append("payment_zip_code", firstStepData.zipcode);
    } else if (data.payment_choose_later === "paypal") {
      formData.append("payment_username", usernameFinalStepData);
      formData.append("payment_address", addressFinalStepData);
      formData.append("payment_city", cityFinalStepData);
      formData.append("payment_state", staFinalStepData);
      formData.append("payment_country", countryFinalStepData);
      formData.append("payment_zip_code", zipFinalStepData);
    }

    try {
      const response = await mutateAsync(formData);
      console.log(response, "reponse");
      console.log(response.data, "responsedata");
      if (response.data.success === 1) {
        setOpen(true);
        reset();
        setCurrentStep(1);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      if (error?.response?.status === 422) {
        alert(error.response.data.message.email);
      }
      console.error("Submission error: ", error);
    }
  };
  const handleVerify = (token) => {
    setToken(token);
  };

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handlePaymentChange = (event) => {
    setShowPayment(event.target.value === "paypal");
  };

  return (
    <div className="rounded-lg bg-black py-5 px-12 h-full mx-4 md:w-10/12 lg:w-9/12 flex flex-col justify-center md:mx-auto max-w-[800px] font-primary relative">
      <p className="text-center text-white text-xl px-4">
        We pay you through PayPal or Money Bookers
      </p>
      <p className="text-center text-white text-xl px-4 mb-8">
        (We plan to offer more options in the future).
      </p>
      <p className="text-white text-xl mb-3">
        Please enter your billing information:
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal open={open} setOpen={setOpen} />
        <div className="relative">
          <div className="flex gap-32 mb-10">
            <div>
              <input
                type="radio"
                id="paypal"
                name="payment_choose_later"
                value="paypal"
                className="cursor-pointer"
                {...register("payment_choose_later")}
                onChange={handlePaymentChange}
                defaultChecked={showPayment}
              />
              <label
                htmlFor="paypal"
                className="text-lg text-white font-bold ml-2 cursor-pointer"
              >
                Paypal
              </label>
            </div>
            <div>
              <input
                type="radio"
                id="choose_later"
                name="payment_choose_later"
                value="choose_later"
                className="cursor-pointer"
                {...register("payment_choose_later")}
                onChange={handlePaymentChange}
                checked={!showPayment}
              />

              <label
                htmlFor="choose_later"
                className="text-lg text-white font-bold ml-2 cursor-pointer"
              >
                Choose Later
              </label>
            </div>
          </div>
          {showPayment && (
            <div>
              {/* <div>
                <input
                  type="checkbox"
                  id="is_billing_address_same_as_contact_address"
                  name="is_billing_address_same_as_contact_address"
                  {...register("is_billing_address_same_as_contact_address")}
                  onChange={() => setSameAddress(!sameAddress)}
                />
                <label
                  htmlFor="is_billing_address_same_as_contact_address"
                  className="text-base text-white ml-2"
                >
                  Same as Contact Information
                </label>
              </div> */}
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-20 gap-y-6 mb-8 mt-5">
                <div className="w-full relative">
                  <input
                    type="text"
                    placeholder="Paypal Username*"
                    className="rounded pl-3 text-black py-1 w-full"
                    id="paypal_username"
                    name="paypal_username"
                    {...register("paypal_username", { required: true })}
                    onChange={handleInputChange(setUsernameFinalStepData)}
                    defaultValue={usernameFinalStepData}
                  />
                  {errors.paypal_username && (
                    <span className="text-red text-xs absolute left-0 -bottom-5">
                      This field is required
                    </span>
                  )}
                </div>
                <textarea
                  name="billing_address"
                  id="billing_address"
                  cols="30"
                  rows="4"
                  placeholder="Address"
                  className="rounded pl-3 text-black py-1"
                  defaultValue={
                    sameAddress ? firstStepData.address : addressFinalStepData
                  }
                  onChange={handleInputChange(setAddressFinalStepData)}
                />
                {errors.billing_address && (
                  <span className="text-red text-xs absolute left-0 -bottom-5">
                    This field is required
                  </span>
                )}
                <div className="w-full relative">
                  <input
                    type="text"
                    placeholder="City"
                    className="rounded pl-3 text-black py-1 w-full"
                    id="billing_city"
                    name="billing_city"
                    defaultValue={
                      sameAddress ? firstStepData.city : cityFinalStepData
                    }
                    onChange={handleInputChange(setCityFinalStepData)}
                  />
                  {errors.billing_city && (
                    <span className="text-red text-xs absolute left-0 -bottom-5">
                      This field is required
                    </span>
                  )}
                </div>
                <input
                  type="text"
                  placeholder="State"
                  className="rounded pl-3 text-black py-1"
                  id="billing_state"
                  name="billing_state"
                  defaultValue={
                    sameAddress ? firstStepData.state : staFinalStepData
                  }
                  onChange={handleInputChange(setStaFinalStepData)}
                />
                <select
                  className="rounded pl-3 text-black py-1"
                  id="billing_country"
                  name="billing_country"
                  defaultValue={
                    sameAddress ? firstStepData.country : countryFinalStepData
                  }
                  onChange={handleInputChange(setCountryFinalStepData)}
                >
                  <option className="text-gray-700 text-lg" disabled selected>
                    Select Country
                  </option>
                  {CountryArray &&
                    CountryArray.map(({ name }, index) => (
                      <option
                        key={index}
                        value={name}
                        className="text-gray-700 text-lg"
                      >
                        {name}
                      </option>
                    ))}
                </select>
                <input
                  type="text"
                  placeholder="Zip Code"
                  className="rounded pl-3 text-black py-1"
                  id="billing_zipcode"
                  name="billing_zipcode"
                  defaultValue={
                    sameAddress ? firstStepData.zipcode : zipFinalStepData
                  }
                  onChange={handleInputChange(setZipFinalStepData)}
                />
              </div>
            </div>
          )}
          <HCaptcha
            sitekey="b6b06718-08ae-457f-a6d7-147046b4c216"
            onVerify={handleVerify}
            ref={captchaRef}
          />
          <p className="text-white mt-10 mb-2">
            By clicking the button below, you are agreeing to the Users{" "}
            <a
              href="https://www.tomedes.com/user-agreement"
              target="_blank"
              className="text-lightBlue"
            >
              Terms of Service
            </a>
            &nbsp;and&nbsp;
            <a
              href="https://www.tomedes.com/privacy"
              target="_blank"
              className="text-lightBlue"
            >
              Privacy Policy.
            </a>
          </p>
          <p className="text-white pb-16">
            If you encounter any problems signing up, please{" "}
            <a
              href="https://www.tomedes.com/contactus.php"
              target="_blank"
              className="text-lightBlue"
            >
              Contact
            </a>
            &nbsp;us or&nbsp;
            <a
              href="https://server.iad.liveperson.net/hc/4900916/?cmd=file%C2%A0file=visitorWantsToChat%C2%A0site=4900916%C2%A0byhref=1%C2%A0imageUrl=https://server.iad.liveperson.net/hcp/Gallery/ChatButton-Gallery/English/General/1a/"
              className="text-lightBlue"
            >
              chat now
            </a>
            &nbsp;with our live agents.
          </p>
          <button
            className="bg-lightBlue text-white max-w-max rounded py-1 px-5 absolute right-0 bottom-0"
            disabled={status === "loading"}
          >
            {status === "loading" ? <Loader /> : "Submit"}
          </button>
        </div>
      </form>

      <button
        onClick={() => setCurrentStep(2)}
        className="text-white absolute bottom-[-40px] left-0 hover:text-lightBlue"
      >
        Return to second step
      </button>
    </div>
  );
}

export default ThirdStepAccount;
