export const ServicesData = [
  { serviceName: "Translation" },
  { serviceName: "Editing" },
  { serviceName: "Proofreading" },
  { serviceName: "DTP" },
  { serviceName: "Content Writing" },
  { serviceName: "Copywriting" },
  { serviceName: "Hostess" },
  { serviceName: "Consecutive Interpretation" },
  { serviceName: "Simultaneous Interpretation" },
  { serviceName: "Live Captioning" },
  { serviceName: "Recording and Transcription" },
  { serviceName: "SEO" },
  { serviceName: "Subtitles Translation" },
  { serviceName: "Transcreation" },
  { serviceName: "Transcription (including timecoding)" },
  { serviceName: "Typing " },
  { serviceName: "Voiceover" },
  { serviceName: "Captioning (Implimentations and subtitles)" },
  { serviceName: "Technical Services" },
  { serviceName: "MTPE (Post Editing MT" },
  { serviceName: "ATPE(Audio Transcription - Post Editing)" },
  { serviceName: "VTPE(Video Transcription - Post Editing)" },
  { serviceName: "Sign Language" },
  { serviceName: "Delivery" },
  { serviceName: "AI Content Editing" },
];
