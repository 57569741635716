export const ExpertField = [
  { fieldsName: "Academic Material'" },
  { fieldsName: "Accounting" },
  { fieldsName: "Advertising / Public Relations" },
  { fieldsName: "Aeronautics" },
  { fieldsName: "Agriculture" },
  { fieldsName: "Apps Games / Video Games / Gaming / Casino" },
  { fieldsName: "Architecture" },
  { fieldsName: "Art, Arts &amp; Crafts, Painting" },
  { fieldsName: "Automotive / Cars &amp; Trucks" },
  { fieldsName: "Biblical" },
  { fieldsName: "Biology" },
  { fieldsName: "Botany" },
  { fieldsName: "Business/Commerce" },
  { fieldsName: "Certificates, Diplomas, Licenses, CVs'" },
  { fieldsName: "Civil Engineering" },
  { fieldsName: "Computers" },
  { fieldsName: "Construction" },
  { fieldsName: "Cosmetics" },
  { fieldsName: "Crytocurrency'" },
  { fieldsName: "CV" },
  { fieldsName: "Engineering (general)" },
  { fieldsName: "Finance" },
  { fieldsName: "Geography" },
  { fieldsName: "Human Resources" },
  { fieldsName: "Industrial Engineering" },
  { fieldsName: "Insurance'" },
  { fieldsName: "Investment / Securities" },
  { fieldsName: "IT (Information Technology)" },
  { fieldsName: "Legal - Corporate" },
  { fieldsName: "Legal - Employment" },
  { fieldsName: "Legal - Patent'" },
  { fieldsName: "Legal - Real Estate" },
  { fieldsName: "Legal - Tax" },
  { fieldsName: "Legal - Track Changes'" },
  { fieldsName: "Legal / Law" },
  { fieldsName: "Literature" },
  { fieldsName: "Marketing" },
  { fieldsName: "Mechanics" },
  { fieldsName: "Medical" },
  { fieldsName: "Menus (culinary)" },
  { fieldsName: "Military / Defense" },
  { fieldsName: "Music" },
  { fieldsName: "Patents" },
  { fieldsName: "Pharmaceutical" },
  { fieldsName: "Politics" },
  { fieldsName: "Psychology" },
  { fieldsName: "Religion" },
  { fieldsName: "Retail" },
  { fieldsName: "Robotics" },
  { fieldsName: "Scientific" },
  { fieldsName: "Technical" },
  { fieldsName: "Technical - Cyber Security" },
  { fieldsName: "Textiles / Clothing / Fashion" },
  { fieldsName: "TO BE UPDATED'" },
  { fieldsName: "Tourism &amp; Travel" },
  { fieldsName: "Track Changes" },
  { fieldsName: "Transport / Transportation / Shipping" },
];
